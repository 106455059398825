import React from "react"
import { graphql } from "gatsby"
import Layout from '../components/pagelayout'
import Hero from '../components/sections/hero'
import CardsSection from '../components/sections/cardsSection'
import TeamSection from "../components/sections/teamSection"
import ParralaxSextion from "../components/sections/parallaxSection"
import Contact from '../components/sections/contactSection'
import TestimonialSection from '../components/sections/testimonialSection'
import TextImageSection from "../components/sections/textImageSection"
import TextSection from '../components/sections/textSection'
import {GatsbySeo} from 'gatsby-plugin-next-seo'
import Gallery from '../components/sections/gallery'
import ClientsSection from "../components/sections/clients"
import MasonryGalley from '../components/layout/masonry'
function renderElements(element){
    switch(element._type) {
        case "hero": {
            return <Hero key={element.key} data={element}/>
        }
        case "cardsSection":{
          return <CardsSection key={element.key} data={element}/>
        }
        case "teamSection":{
          return <TeamSection key={element.key} data={element}/>
        }
        case "parallaxSection":{
          return <ParralaxSextion key={element.key} data={element}/>
        }
        case "contactSection":{
          return <Contact key={element.key} data={element}/>
        }
        case "testimonialSection":{
          return <TestimonialSection key={element.key} data={element}/>
        }
        case "textImageSection":{
          return <TextImageSection key={element.key} data={element}/>
        }
        case "textSection":{
          return <TextSection key={element} data={element} />
        }
        case "gallery":{
          if(element.fullGallery){
            return <MasonryGalley key={element.key} data={element}/>
          }
          else
          return <Gallery key={element.key} data={element}/>
        }
        case "clients":{
          return <ClientsSection data={element} key={element.key}/>
        }
        default:{
          return <></>
        }
    }
}
const IndexPage = ({data}) => {
const content=data.sanityPage.content
return(
<><GatsbySeo
   description={data.sanityPage.description}
   title={`${data.sanityPage.title}-Chambers and Jackett Ltd.`}
   />
 <Layout>

 {content.map((elements,index)=>(
  <div  key={index}> {renderElements(elements)}</div>
 ))}

 </Layout>
 </>
)
}

export default IndexPage
export const query=graphql`
query MyQuery {
  sanityPage(title: {eq: "Home"}) {
    title
    description
    content {
      ... on SanityClients {
        _key
        _type
        clientLogos {
          asset {
            fluid(maxWidth: 500){
              ...GatsbySanityImageFluid_withWebp_noBase64
            }
          }
          alt
        }
      }
      ... on SanityCardsSection {
        _type
        heading
        subtext
        message {
          _rawText(resolveReferences: {maxDepth: 10})
        }
        cards {
          heading
          label
          cta {
            title
            route {
              custom_name
              slug {
                current
              }
            }
          }
          text {
            _rawText(resolveReferences: {maxDepth: 10})
          }
          image {
            alt
            caption
            asset {
              fluid(maxWidth: 500){
                ...GatsbySanityImageFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      ... on SanityHero {
        _key
        _type
        slides {
          heading
          mobile:backgroundImage {
            alt
            caption
            asset {
             fluid(maxWidth:500) {
                ...GatsbySanityImageFluid_withWebp_noBase64
              }
            }
          }
          desktop:backgroundImage {
            alt
            caption
            asset {
             fluid(maxWidth:3000) {
                ...GatsbySanityImageFluid_withWebp_noBase64
              }
            }
          }
          cta {
            title
            route {
              custom_name
              slug {
                current
              }
            }
          }
          tagline {
            _rawText
          }
        }
      }
      ... on SanityParallaxSection {
        _key
        _type
        cta {
          title
          route {
            slug {
              current
            }
            custom_name
          }
        }
        heading
        subtitle
        text {
          _rawText
        }
        image {
          alt
          caption
          asset {
            fluid(maxWidth:3000){
              ...GatsbySanityImageFluid_withWebp_noBase64
            }
          }
        }
      }
      ... on SanityTeamSection {
        _key
        _type
        heading
        message {
          _rawText
        }
        subtext
        teamMembers {
          name
          position
          image {
            asset {
              fluid( maxWidth: 300 ) {
                ...GatsbySanityImageFluid_withWebp_noBase64
              }
            }
            crop {
              left
              right
              top
              bottom
            }
          }
          message {
            _rawText
          }
        }
      }
      ... on SanityTestimonialSection {
        _key
        _type
        heading
        iconBlocks {
          backgroundColor
          heading
          iconClass
          message
        }
        message {
          _rawText
        }
        testimonial {
          writerName
          writerPosition
          companyLogo {
            asset {
              fluid(maxWidth: 200){
                 ...GatsbySanityImageFluid_withWebp_noBase64
              }
            }
            alt
            caption
          }
          testimonialText {
            _rawText
          }
        }
        title
      }
      ... on SanityTextImageSection {
        _key
        _type
        cta {
          title
          route {
            slug {
              current
            }
            custom_name
          }
        }
        heading
        images {
          alt
          caption
          asset {
            fluid(maxWidth:500) {
              ...GatsbySanityImageFluid_withWebp_noBase64
            }
          }
        }
        imagePosition
        label
        text {
          _rawText
        }
      }
      ... on SanityTextSection {
        _key
        _type
        heading
        label
        text {
          _rawText
        }
      }
      ... on SanityContactSection {
        _type
        mapString
      }
      ... on SanityGallery {
        _key
        _type
        description
        title
        fullGallery
        fullWidth:images {
          alt
          caption
          asset {
            fluid{
              ...GatsbySanityImageFluid_withWebp_noBase64
            }
            url
          }
        }
        thumbnails:images {
          alt
          caption
          asset {
            fluid (maxWidth: 400){
              ...GatsbySanityImageFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
}
`